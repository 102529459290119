<template>
  <div class="body-content" @click="goToOrder">
    <!--   标签   -->
    <div
      class="tag"
      :style="{ backgroundColor: setTagColor, width: setFontWidth }"
    >
      {{ sortFlag }}
    </div>
    <!--   信息列表   -->
    <div class="message-list">
      <ul>
        <li
          v-for="(item, index) in showBaseMessage"
          :key="index"
          class="content"
        >
          <span>{{ item.label + '：' }}</span>
          <span class="base-message-content">{{ item.value }}</span>
        </li>
      </ul>
      <div class="extend">
        <img
          width="14px"
          height="8px"
          src="~@/assets/img/orderpage/default/extend.png"
          v-if="iconShowFlag"
          v-show="showHidden"
          @click.stop="showDetail"
          alt="详情"
        />
        <img
          width="14px"
          height="8px"
          v-if="iconShowFlag"
          v-show="!showHidden"
          src="~@/assets/img/orderpage/default/retract.png"
          @click.stop="showDetail"
          alt="详情"
        />
      </div>
      <!--   分割线   -->
      <div class="divider"></div>
    </div>
    <!--   缴费信息   -->
    <div class="amount-list">
      <span
        v-for="(item, index) in payMessages"
        :key="index"
        :style="{
          backgroundColor: setAmountTagColor(index)
          // border: setAmountTagColor(index)[1]
        }"
        >{{ item.label }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'BodyContent',
  props: {
    sortFlag: String,
    baseMessages: Array,
    payMessages: Array
  },
  data() {
    return {
      iconShowFlag: false, //展示图标
      showHidden: true, //显示详情图标
      iconPath: '', //图标路径
      showBaseMessage: [], //展示的基本信息
      showPayMessage: [] //展示缴费信息
    }
  },
  computed: {
    setTagColor() {
      // 设置标签颜色， 红 ff5151  褐d0ae73  绿72c34b  蓝38a4ef   紫ba70e0  黄f49e23  青08acbf
      switch (this.sortFlag) {
        case '未支付':
          return '#ff5151'
        case '部分支付':
          return '#f49e23'
        case '支付完成':
          return '#72c34b'
        case '未支付（有退款）':
          return '#ba70e0'
        case '部分支付（有退款）':
          return '#38a4ef'
        case '支付完成（有退款）':
          return '#08acbf'
        default:
          return '#d0ae73'
      }
    },
    setFontWidth() {
      //设置宽度
      return this.sortFlag.length * 11 + 22 + 'px'
    },
    setAmountTagColor() {
      //设置费用tag的背景色
      return (index) => {
        switch (index % 5) {
          case 0:
            return '#4ecebd'
          case 1:
            return '#f4b326'
          case 2:
            return '#51b3f2'
          case 3:
            return '#dc92f7'
          default:
            return '#f9a293'
        }
      }
    }
  },
  methods: {
    pageInit() {
      // 初始化
      if (this.baseMessages.length > 3) {
        this.showBaseMessage = this.baseMessages.slice(0, 3)
        this.iconShowFlag = true
      } else {
        this.showBaseMessage = this.baseMessages
      }
    },
    showDetail() {
      //展示详细信息
      this.showHidden = !this.showHidden
      if (this.showHidden) {
        this.showBaseMessage = this.baseMessages.slice(0, 3)
      } else {
        this.showBaseMessage = this.baseMessages
      }
    },
    goToOrder() {
      //发射跳转事件
      this.$emit('goToOrderpage')
    }
  },
  mounted() {
    this.pageInit()
  }
}
</script>

<style scoped lang="less">
.body-content {
  //根
  width: 355px;
  margin: 15px auto;
  border-radius: 10px;
  background-color: white;
  padding: 12px 0 0 0;
  box-shadow: 1px 1px 3px #bbbbbb;

  .tag {
    //标签
    line-height: 20px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    color: #fffefe;
    border-radius: 0 10px 10px 0;
    margin-bottom: 5px;
  }
  .message-list {
    //基本信息
    width: 335px;
    padding: 0 5px;

    .content {
      //基本信息内容
      font-size: 12px;
      color: #333333;
      display: flex;
      justify-content: space-between;
      padding: 2px 10px;

      .base-message-content {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0;
        color: #999999;
      }
    }

    .extend {
      display: flex;
      justify-content: center;
      padding-bottom: 5px;
    }

    .divider {
      //分割线
      height: 1px;
      background-color: #e5e5e5;
      color: red;
      width: 100%;
    }
  }
  .amount-list {
    //缴费信息
    padding: 5px;
    display: flex;
    justify-content: start;
    flex-wrap: wrap;

    span {
      margin: 5px;
      font-size: 11px;
      padding: 2px 10px;
      background-color: #13ce66;
      border-radius: 6px;
      color: white;
    }
  }
}
</style>
