<template>
  <div class="search-page">
    <head-text class="head-text" :title="this.$store.state.title">
      <card
        slot="card"
        :searchKeyWord="searchKeyWord"
        @emitOrderMessageSort="setOrderMessages"
      ></card>
    </head-text>
    <div class="body-content">
      <body-content
        v-for="item in orderMessages"
        :key="item.listID"
        :sortFlag="sortFlagBy(item)"
        :base-messages="cellBaseMessage(item)"
        :pay-messages="cellPayMessage(item)"
        @goToOrderpage="jumpOrderPage(item)"
      ></body-content>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import request from '@/api/request'
import { judgeUA } from '@/utils/functions'
import Card from '@/components/orderPage/default/Card.vue'
import HeadText from '@/components/common/default/HeadText.vue'
import BodyContent from '@/components/orderPage/default/BodyContent'

export default {
  name: 'SearchPage',
  data() {
    return {
      searchKeyWord: [], // 辅助接口查询关键字
      orderMessages: this.$store.state.orderMessages // 所有订单信息
    }
  },
  components: {
    BodyContent,
    HeadText,
    Card
  },
  computed: {
    sortFlagBy() {
      return (item) => {
        switch (item.sortFlag) {
          case 0:
            return item.refunded ? '未支付（有退款）' : '未支付'
          case 1:
            return item.refunded ? '部分支付（有退款）' : '部分支付'
          case 2:
            return item.refunded ? '支付完成（有退款）' : '支付完成'
          case 4:
            return '已退款'
        }
      }
    },
    cellBaseMessage() {
      return (message) => {
        let basemessage = []
        for (const baseElement of message.baseMessage) {
          basemessage.push({
            label: baseElement.value,
            value: baseElement.display
          })
        }
        return basemessage
      }
    },
    cellPayMessage() {
      return (message) => {
        let paymessage = []
        for (let i = 0; i < message.payMessage.length; i++) {
          paymessage.push({
            label: message.payMessage[i].subjectName,
            orderStatus: message.payMessage[i].orderStatus
          })
        }
        return paymessage
      }
    }
  },
  methods: {
    setOrderMessages(orderMessages) {
      this.orderMessages = orderMessages
    },
    jumpOrderPage(order) {
      this.$router.push({
        path: 'orderpage',
        query: { code: this.$route.query.code }
      })
      this.$store.commit('setOderMessage', order)
    }
  },
  mounted() {
    // 获取AppID
    ;(async () => {
      let templateId = this.$route.path.split('/')[2]
      let promiseAppId
      try {
        promiseAppId = await request.get(
          `/payment/wx/school/page/${templateId}/appid`
        )
      } catch (err) {
        console.error(err)
        Toast.fail(err.message)
      }
      // 判断ua获取code
      if (
        judgeUA(this.$router) &&
        !this.$route.query.code &&
        promiseAppId.code === 'success'
      ) {
        // 获取code
        let redirect = encodeURIComponent(
          process.env.VUE_APP_WX_REDIRECT_URL + this.$route.path
        )

        if (promiseAppId.content) {
          let appid = promiseAppId.content
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect}&response_type=code&scope=snsapi_base#wechat_redirect`
        }
      }
    })()
    //辅助接口
    request
      .get(
        `payment/wx/school/order/template/${this.$route.params.templateId}/forQuery`
      )
      .then((res) => {
        let actions = []
        for (const re of res.content.infoIdAndNames) {
          actions.push({ text: re.display, infoId: re.value })
        }
        document.title = res.content.templateName // 设置标题栏
        this.$store.commit('setSplitOrder', res.content.split)
        this.$store.commit('setTitle', res.content.templateName)
        this.$store.commit(
          'setStyle',
          res.content.style ? JSON.parse(res.content.style) : {}
        )
        this.searchKeyWord = actions
      })
      .catch((err) => {
        console.error(err)
        Toast.fail('辅助接口查询失败！')
      })
  }
}
</script>

<style scoped lang="less">
.search-page {
  .head-text {
    margin-bottom: 40px;
  }
  .body-content {
    margin-bottom: 50px;
    .body-content {
      margin-bottom: 10px;
    }
  }
}
</style>
