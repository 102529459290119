import { render, staticRenderFns } from "./SearchPage.vue?vue&type=template&id=40d7c586&scoped=true&"
import script from "./SearchPage.vue?vue&type=script&lang=js&"
export * from "./SearchPage.vue?vue&type=script&lang=js&"
import style0 from "./SearchPage.vue?vue&type=style&index=0&id=40d7c586&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.2_uesoswnqfrvbmgjykhrr3r374i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d7c586",
  null
  
)

export default component.exports