<template>
  <div class="tab-nav">
    <div
      id="tab"
      v-for="(item, index) in items"
      :key="item.text"
      @click="selectHandle(index)"
      :class="{ line: index === selectedItem }"
    >
      {{ item.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    items: Array
  },
  data() {
    return {
      selectedItem: 0 // 选择的
    }
  },
  methods: {
    selectHandle(index) {
      this.selectedItem = index
      this.$emit('sendSelectedItem', this.items[index])
    }
  }
}
</script>

<style scoped lang="less">
.tab-nav {
  margin-top: 5px;
  margin-left: -6px;
  color: #999999;
  display: flex;

  div {
    margin-left: 42px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
  }
}
.line {
  color: #3975f1;
  border-bottom-color: #3975f1;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}
</style>
